import type { NextPage } from "next";
import { createClient } from "../../prismicio";
import { SliceZone } from "@prismicio/react";
import { PrismicDocumentWithUID } from "@prismicio/types";
import { components } from "../slices";
import React from "react";
import { getSlice } from "../utils/sliceHelper";
import MetaFields from "../components/MetaFields";
import LDJson from "../components/ldjson";

interface IPreviewData {
  ref?: string;
}

interface IPageProps {
  params: any;
  locale?: string;
  previewData: IPreviewData;
}

export async function getStaticProps({ previewData }: IPageProps) {
  let page: PrismicDocumentWithUID;
  let uid;
  const client = await createClient({ previewData });
  const mapLocale = "nl-nl";

  try {
    uid = "404";
    page = await client.getByUID("pages", uid, { lang: mapLocale });
  } catch (e: any) {
    console.error(e);
    return {
      notFound: true,
    };
  }

  const globalSettings = await client.getSingle("global_settings", {
    lang: mapLocale,
  });

  const jobs = await client.getAllByTag("Vacatures", {
    lang: mapLocale,
  });
  // console.log(globalSettings, mapLocale);
  const navigation = getSlice(
    globalSettings.data.slices,
    "slice_type",
    "navigation"
  );
  const footer = getSlice(globalSettings.data.slices, "slice_type", "footer");
  const services = getSlice(
    globalSettings.data.slices,
    "slice_type",
    "services"
  );
  // @ts-ignore
  const footerSections = globalSettings.data.slices.filter((slice: any) => {
    return slice
      ? ["location", "contact", "links", "socials"].includes(slice.slice_type)
      : null;
  });

  return {
    props: {
      page,
      jobsCount: jobs?.length || 9,
      services,
      footer,
      navigation,
      globalSettings,
      footerSections,
      env: {
        SITE_URL: process.env.SITE_URL,
        ROBOTS: process.env.ROBOTS,
      },
    },
  };
}

const Custom404: NextPage = ({
  page,
  jobsCount,
  footer,
  globalSettings,
  footerSections,
  env,
}: any) => {
  const separate = ["header", "breadcrumb"];

  //const page_router = useRouter();
  return (
    <>
      {/* These will be added to <head> */}
      <MetaFields
        page={page}
        globalSettings={globalSettings}
        pageRouter={""}
        env={env}
      />
      <LDJson page={page} globals={globalSettings} />
      {/* </head> */}

      <header className="overflow-hidden flex flex-col mb-28 sm:mb-48">
        <SliceZone
          slices={page.data.slices.filter((slice: any) =>
            separate.includes(slice.slice_type)
          )}
          components={{ ...components }}
          context={{ globalSettings, jobsCount }}
        />
      </header>

      <main className="overflow-x-hidden flex flex-col space-y-28 sm:space-y-48 mb-28 sm:mb-48 xl:px-0 sm:px-8 px-2.5">
        <SliceZone
          slices={page.data.slices.filter(
            (slice: any) => !separate.includes(slice.slice_type)
          )}
          components={{ ...components }}
          context={{ globalSettings }}
        />
      </main>
      <footer className="bg-brand-marine">
        <SliceZone
          slices={[footer]}
          components={{ ...components }}
          context={{ footer, globalSettings, footerSections }}
        />
      </footer>
    </>
  );
};
export default Custom404;
